import * as React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const srsa = () => {
	return(
		<ModalRoutingContext.Consumer>
		{({ modal, closeTo }) => (
			<>
			  <Link 
			  	className="Modal-close SRSA"
			  	to={closeTo}
			  	state={{noScroll: true }}
			  >
				<span></span>
			  </Link>
			<div className="Work-wrap SRSA-modal">
				<img className="city" src={'work/srsa/city.svg'} />
				<ul className="Work-slides">
					<li className="spacer"></li>
					<li className="Title">
						<h3 className="font-title text-center">SRSA Real Estate</h3>
					</li>
					{/* <li className="Work">
						<div>
							
						</div>
					</li> */}
					<li className="Work">
						<div className="SRSA-split">
							<svg className="better-svg half-logo" viewBox="0 0 62 77.1" width="350" height="435" preserveAspectRatio="xMinYMin meet">
								<g id="left">
									<polygon id="b-l" className="cls-left" points="0 0.7 0 11.7 0 77.1 30.7 77.1 0 0.1 0 0.7"></polygon>
									<polygon id="b-r" className="cls-left" points="30.7 0.1 61.6 77.1 61.6 0.1 30.7 0.1"></polygon>
									<path id="s" className="cls-left" d="M13.7,11.1a67.7,67.7,0,0,0,7.5.6c2.6.1,3.2.4,3.7,1.8s.2,1.2-.1,1.5-.9.4-2.9.4-2.7,0-3.4-.4a2.6,2.6,0,0,1-1.3-1.6V13H10.4c.9,2.3,1.5,3.3,2.7,4.2s4.3,2,10.4,2,6.5-.3,7.7-1.4.9-2.1-.1-4.7-2.5-4.3-5.4-4.9S24,7.8,18,7.5c-2.1-.1-2.3-.1-2.8-.4A2.4,2.4,0,0,1,14,5.8c-.6-1.5,0-1.9,3.2-1.9s4,.3,4.6,1.9v.3H28l-.4-.9A7.4,7.4,0,0,0,25,1.7C23.3.4,20.6,0,15.5,0S8.9.3,7.6,1.2,6.4,3.4,7.3,5.7a8.9,8.9,0,0,0,6.4,5.4"></path>
									<path id="r" className="cls-left" d="M29.2,29.6H24l-2.1-5.1h4.8c2.3,0,3,.4,3.7,2.2s.8,2.9-1.3,2.9M36.3,26a8.1,8.1,0,0,0-4-4.6c-1.8-.9-3.3-1-6.7-1H13.7l7.4,18.3h6.7l-2-4.9h4.9a3.5,3.5,0,0,1,3.7,2.4l1,2.5h6.2l-1.5-3.4c-.9-2.3-2.8-3.5-5.8-3.6v-.2a5.1,5.1,0,0,0,2.5-.9c.9-.7.8-1.9-.4-4.6"></path>
									<path id="s-2" className="cls-left" d="M33.3,43.5c2.8,0,3.9.3,4.4,1.8v.3H44l-.3-.8A6.7,6.7,0,0,0,41,41.3c-1.7-1.2-4-1.6-9.3-1.6s-7,.3-8.3,1.2-1.2,2.2-.2,4.4a8.6,8.6,0,0,0,6.2,5.1,68.9,68.9,0,0,0,7.4.5c2.6.1,3.4.4,3.9,1.6s.2,1.1-.2,1.4-1,.4-3,.4-2.6,0-3.3-.3A2.3,2.3,0,0,1,33,52.5v-.3H26.1c.9,2.1,1.5,3,2.6,3.8s4.2,1.8,10.2,1.8,6.5-.3,7.7-1.3,1-1.9.3-4.2a6.2,6.2,0,0,0-5.1-4.6c-2.9-.6-1.7-.3-7.8-.7-2.1-.1-2.4-.1-2.9-.4a2.2,2.2,0,0,1-1-1.3c-.6-1.5.1-1.8,3.3-1.8"></path>
									<path id="a" className="cls-left" d="M41.3,70.4V62.5l5.5,7.9Zm-6-11.5V77.1h6V73.9h7.8L51.3,77h5.9L44.3,58.9Z"></path>
								</g>
							</svg>
						</div>
					</li>
					<li className="Work">
						<div>
						<picture>
								<source srcset={'/work/srsa/1/1-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/srsa/1/1-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/srsa/1/1-c.jpg'} media="(max-width: 34em)" />
								<img src={"/work/srsa/1/1-a.png"} alt="srsa Work Example" />
							</picture>
						</div>
					</li>
					<li className="Work">
						<div className="SRSA-goto goto">
							<p>SRSA Commercial Real Estate is a full-service commercial real estate company serving the Gulf South region. Named the 3rd Largest Property Management Company in Louisiana.</p>
							<a target="blank" rel="noreferrer" href="https://www.srsa-realestate.com"><p>View Site</p></a>
						</div>
					</li>
				</ul>
		  </div>
		  </>
		)}
	  </ModalRoutingContext.Consumer>
	)
}

export default srsa